@import 'styles/theme';

.TextHorizontalScrolling {
  position: relative;
  width: 100%;
  overflow: hidden;

  .text {
    display: block;
    white-space: nowrap;
    margin-right: theme($spacings, 20);
    line-height: 0.9;
  }

  &.isWhite {
    .text {
      @include fontSmoothed();
    }
  }
}
