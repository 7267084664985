@import 'styles/theme';

.HeroMainTeasingScroll {
  position: relative;
  width: 100%;

  @include switchDesktopMobileClasses('md');

  .imageContainer {
    position: relative;
    width: 100%;
    height: stableVh(130);
    cursor: pointer;

    @include from('md') {
      min-height: 1200px;
      height: stableVh(200);
      min-height: 1500px;
    }
  }

  .imageContent,
  .imageContentAnimated {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .title {
    grid-column: 2 / -2;
    width: 100%;
    display: block;
    margin-top: theme($spacings, 25);
  }

  .image {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.2s opacity;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .stickyContainer {
    z-index: 1;
    position: sticky;
    top: 0;
    left: 0;
    height: stableVh(100);
  }

  .links {
    z-index: 2;
    position: absolute;
    top: theme($spacings, 35);
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    .linksContainer {
      grid-column: 2 / -2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .repeatTextsContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: stableVh(100);

    @include from('md') {
      height: stableVh(50);
    }
  }

  .repeatTexts {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    bottom: theme($spacings, 10);

    .repeatSmallItem {
      margin-right: theme($spacings, 60);
    }

    .repeatText {
      &:last-of-type {
        letter-spacing: -1px;
        font-weight: 700;
      }
    }
  }

  .bottomFormContainer {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .background {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .bottomForm {
    background-color: white;
    position: relative;
    padding: theme($spacings, 50) 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    overflow: auto;
  }

  .marker {
    visibility: hidden;
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 1px;
  }

  .closeIcon {
    position: absolute;
    width: 15px;
    cursor: pointer;
    top: 15px;
    right: 15px;

    @include from('md') {
      top: 30px;
      right: 30px;
    }
  }
}
