@use 'sass:math';
@import 'styles/theme';

.PushProductsImages {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .title {
    text-transform: uppercase;
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 2 / -8;
    }

    + .description {
      margin-top: theme($spacings, 10);

      @include from('md') {
        margin-top: theme($spacings, 20);
      }
    }
  }

  .description {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 2 / -8;
    }

    + .container {
      margin-top: theme($spacings, 20);

      @include from('md') {
        margin-top: theme($spacings, 30);
      }
    }
  }

  .imageContainer {
    overflow: hidden;
  }

  .container {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include from('md') {
      justify-content: space-between;
      grid-column: 2 / -2;
    }

    .image {
      height: auto;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 14.5,
          'mobile': 24,
        )
      );
    }

    .card {
      margin-top: theme($spacings, 20);

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 7,
          'mobile': 22,
        )
      );

      @include from('md') {
        margin-top: 0;
      }
    }
  }

  &.reversed {
    .container {
      flex-direction: column-reverse;
      align-items: center;

      @include from('md') {
        flex-direction: row-reverse;
        align-items: initial;
      }

      .card {
        margin-top: 0;
        margin-bottom: theme($spacings, 20);
      }
    }
  }

  .imageRatio {
    @include applyPercentRatios(
      math.div(360, 530),
      (
        'md': math.div(870, 640),
      )
    );
  }

  .cardRatio {
    @include applyPercentRatios(
      math.div(330, 435),
      (
        'md': math.div(420, 560),
      )
    );
  }
}
