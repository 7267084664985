@import 'styles/theme';

.Input {
  .input {
    border-color: theme($colors, 'alto');
    padding: theme($spacings, 15) theme($spacings, 10);

    &:focus {
      border-color: theme($colors, 'black');
    }

    &.error {
      border-color: currentColor;
      color: theme($colors, 'pomegranate');
    }

    + .errorList {
      margin-top: theme($spacings, 5);
    }
  }

  &.birthDate {
    .input {
      padding: theme($spacings, 13) theme($spacings, 10);
    }
  }
}
