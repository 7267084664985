@import 'styles/theme';

.HeroMain {
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-bottom: theme($spacings, 60);
  }

  .items {
    position: relative;
    grid-column: 1 / -1;
    line-height: 0;
  }

  .containerSliderCover {
    position: relative;
    width: 100%;
  }

  .sliderNavigation {
    position: absolute;
    bottom: 0;
    z-index: 1;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;

    @include applyColumnWidth('right', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('right', 1, $desktop);
      padding-bottom: theme($spacings, 20);
      padding-top: theme($spacings, 20);
    }
  }

  .header {
    width: 100%;
    position: relative;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    margin-top: theme($spacings, 20);

    .titles {
      padding-top: theme($spacings, 10);
      margin-top: -#{theme($spacings, 10)};
      @include applyColumnWidth('margin-left', 1, $mobile);
      @include applyColumnWidth('margin-right', 1, $mobile);

      @include from('md') {
        @include applyColumnWidth('margin-left', 1, $desktop);
        @include applyColumnWidth('margin-right', 5, $desktop);
      }
    }

    .title {
      // Fix for overflow hidden overlap
      &:not(:first-of-type) {
        position: absolute;
        width: 100%;
        left: 0;
      }

      &.theme-black {
        color: theme($colors, 'black');
      }

      &.theme-white {
        color: theme($colors, 'white');
      }
    }
  }

  .texts {
    position: relative;
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 2 / 11;
    }

    .text {
      width: 100%;

      &:not(:first-of-type) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .cta {
    display: inline-block;
  }

  .ctaLabel {
    text-transform: uppercase;
  }

  .subtitle,
  .description,
  .discoverMore,
  .cta {
    margin-top: theme($spacings, 16);
  }

  &.bottom {
    .header {
      @include from('md') {
        grid-row: 2 / 3;
      }
    }

    .sliderNavigation {
      &.dot {
        bottom: 100%;
      }

      &.number {
        @include applyFluidType(
          'margin-top',
          theme($screens, 'md'),
          theme($max, 'width-design'),
          theme($spacings, 2),
          theme($spacings, 30)
        );
      }
    }
  }

  &.center {
    .items,
    .header {
      grid-row: 1 / 2;
      margin-top: 0;
      align-self: center;
    }

    .sliderNavigation {
      &.dot {
        top: 100%;
      }

      &.number {
        margin-top: theme($spacings, 20);
      }
    }
  }

  // Title layouts
  &.titleLayout {
    &-title-on-image {
      .items,
      .header {
        grid-row: 1;
      }

      .header {
        margin-top: auto;
        padding-bottom: theme($spacings, 20);
      }

      .sliderNavigation {
        @include applyColumnWidth('left', 1, $mobile);
        right: auto;

        @include from('md') {
          left: auto;
          @include applyColumnWidth('right', 1, $desktop);
        }
      }

      &.hasSlider {
        .header {
          padding-bottom: theme($spacings, 40);

          @include from('md') {
            padding-bottom: theme($spacings, 20);
          }
        }
      }
    }

    &-title-half-image {
      &.titleSize {
        &-big {
          .header {
            // For the values below :
            // We have to pull the text up by half of their fontSizes
            // 18px : The min fontSize of the text is 45px, with a lineHeight of 0.8, so its (45 * 0.8) / 2 = 18
            // 60px : The max fontSize of the text is 150px, with a lineHeight of 0.8, so its (150 * 0.8) / 2 = 60
            @include applyFluidType(
              'margin-top',
              theme($screens, 'md'),
              theme($max, 'width-design'),
              18px,
              60px,
              -1
            );

            // Kerning fixes
            .title {
              &.isHalfBadKerning {
                @include applyFluidType(
                  'margin-left',
                  theme($screens, 'md'),
                  theme($max, 'width-design'),
                  1px,
                  4px,
                  -1
                );
              }

              &.isFullBadKerning {
                @include applyFluidType(
                  'margin-left',
                  theme($screens, 'md'),
                  theme($max, 'width-design'),
                  1px,
                  9px,
                  -1
                );
              }
            }
          }

          .sliderNavigation {
            @include applyFluidType(
              'bottom',
              theme($screens, 'md'),
              theme($max, 'width-design'),
              22px,
              60px,
              1
            );

            @include from('md') {
              bottom: 0;
            }
          }
        }

        &-small {
          .header {
            margin-top: -#{theme($spacings, 15)};

            @include from('md') {
              margin-top: -#{theme($spacings, 30)};
            }
          }

          .sliderNavigation {
            bottom: theme($spacings, 15);

            @include from('md') {
              bottom: 0;
            }
          }
        }
      }
    }

    &-title-below-image-edito-right {
      .bottomContainer {
        grid-column: 2/-2;
      }

      .titles {
        margin-left: 0;
        margin-right: 0;
      }

      @include from('md') {
        .bottomContainer {
          grid-column: 1/-1;
          display: flex;
          flex-direction: row;
          padding-top: theme($spacings, 60);

          .header {
            margin-top: 0;
            @include applyColumnWidth('margin-left', 1, $desktop);
            @include applyColumnWidth('margin-right', 2, $desktop);
            @include applyColumnWidth('width', 10, $desktop);
          }

          .titles {
            @include applyColumnWidth('margin-right', 2, $desktop);
          }

          .texts {
            margin-top: auto;
            @include applyColumnWidth('width', 10, $desktop);
            @include applyColumnWidth('margin-right', 1, $desktop);
          }
        }
      }
    }
  }
}
