@import 'styles/theme';

.FormEventSlice {
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);

  @include from('md') {
    margin-top: theme($spacings, 120);
    margin-bottom: theme($spacings, 120);
  }
}
