@use 'sass:math';

@import 'styles/theme';

// Those values are measured from the Figma
$columnMeasuredValueMobile: 15;
$columnMeasuredValueDesktop: 60;

.ProductsGridEdito {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .suptitle,
  .title {
    grid-column: 2 / -2;
  }

  .suptitle {
    + .title {
      margin-top: theme($spacings, 16);
    }

    + .content {
      margin-top: theme($spacings, 25);
    }
  }

  .title {
    + .content {
      margin-top: theme($spacings, 25);
    }
  }

  .content {
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    // Measured mobile gap :
    // 10px
    @include applyColumnWidth(
      'column-gap',
      math.div(10, $columnMeasuredValueMobile),
      $mobile
    );
    row-gap: theme($spacings, 30);

    @include from('md') {
      grid-template-columns: repeat(4, 1fr);
      row-gap: theme($spacings, 75);

      // Measured desktop gap :
      // 24px
      @include applyColumnWidth(
        'column-gap',
        math.div(24, $columnMeasuredValueDesktop),
        $desktop
      );
    }
  }

  .cardWidth {
    // Measured mobile card width :
    // 160px
    @include applyColumnWidth(
      'width',
      math.div(160, $columnMeasuredValueMobile),
      $mobile
    );

    @include from('md') {
      // Measured desktop card width :
      // 312px
      @include applyColumnWidth(
        'width',
        math.div(312, $columnMeasuredValueDesktop),
        $desktop
      );
    }
  }

  .footer {
    grid-column: 2 / -2;
    margin-top: theme($spacings, 50);

    @include from('md') {
      margin-top: theme($spacings, 30);
    }
  }
}
